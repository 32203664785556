
const addDotToThirdPosition = (text, index)=> {
    let str = text
    if (str.length >= 3) {
        if (str.charAt(index) !== '·') {
            str = str.slice(0, index) + '·' + str.slice(index);
        }
    }
    return str;
}

const getScore = (orderStatistics) =>{//计算分数
                
    if(!orderStatistics){
        return 5
    }
    Object.keys(orderStatistics).forEach(function(key) {
        orderStatistics[key] = orderStatistics[key] * 1
    });
    if(orderStatistics.orderCompleteNum == 0){
        return orderStatistics.overallRate
    }else{
        let num = (orderStatistics.overallRate - 5)/orderStatistics.orderCompleteNum
        return Math.round(num * 10) / 10;
    }
}

const hideMiddleFour = (phoneNumber) =>{
    if (phoneNumber && phoneNumber.length === 11) { // 确保手机号是11位
        return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    }
    return phoneNumber; // 如果不是有效的11位手机号，则原样返回
}
const findData = (list, id , key1, key2) =>{
    let companyItem = list.find(item=>item[key1] == id)
    return companyItem? companyItem[key2] : ''
}
const mergeObj = (obj1, obj2) => {
    Object.keys(obj2).forEach(key => {
        if (obj1.hasOwnProperty(key)) { // 检查obj1是否有这个属性
            obj1[key] = obj2[key]; // 如果有，就将obj2的值赋给obj1
        }
    });
    return obj1
};
const getDate = (time) => {
    if(time){
        time = new Date(time)

    }else{
        time = new Date()
    }
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let day = time.getDate()
    day = day < 10 ? '0' + day : day
    let hour = time.getHours()
    hour = hour < 10 ? '0' + hour : hour
    let minute = time.getMinutes()
    minute = minute < 10 ? '0' + minute : minute
    let second = time.getSeconds()
    second = second < 10 ? '0' + second : second
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
};
//设置表格第一行的颜色
const getRowClass = ({ row, column, rowIndex, columnIndex }) => {
  if (rowIndex == 0) {
    return "background:#EEEEEE ;color:#666666";
  } else {
    return "";
  }
}
  

export {
    addDotToThirdPosition,
    getScore,
    hideMiddleFour,
    findData,
    mergeObj,
    getDate,
    getRowClass,
};