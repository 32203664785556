<template>
	<div class="">
		<!-- 头部 -->
		<div class="flex ai_center header">
			<img class="logo_img" src="../assets/images/logo.png" alt="" />
			<div class="flex1"></div>
			
			<el-popover
				class="user_info_popover"
				placement="bottom-end"
				width="400"
				trigger="hover">
				<img slot="reference" class="avatar" :src="userInfo.avatar||require('../assets/images/avatar.png')" alt="">
				<!-- 弹出层 -->
				<div class="user_info">
					<div class="flex ai_center">
						<div class="mgr-8 rel avatar_box">
							<img class="avatar" :src="userInfo.avatar||require('../assets/images/avatar.png')" alt="">
							<div class="avatar_box_bg">
								<img src="../assets/images/img.png" alt="">
								
								<el-upload
									class="upload_avatar"
									action="https://genqian.top/test/prod-api/gqManage/upload-file-tencent-cos"
									:multiple="false"
									:accept="'image/*'"
									:before-upload="response => checkSize(response)"
									:on-success="response => handleSuccess(response)">
									<i slot="default" class="el-icon-plus"></i>
								</el-upload>
							</div>
						</div>
						<div class="flex1">
							<div class="user_name flex1">{{userInfo.nickName}}</div>
							<div class="phone">{{ userInfo.phonenumber }}</div>
						</div>
					</div>
					<div class="mgt-20 flex ai_center jc_end">
						<el-button @click="logout" class="common_btn mini" size="mini">退出登录</el-button>
					</div>
				</div>
			</el-popover>
		</div>
		<!-- 主体 -->
		<div class="main flex">
			<!-- 左边导航 -->
			<div class="nav">
				<img class="line-img" src="../assets/images/line.png" alt="">
				<el-menu :default-active="$route.path" class="el-menu-vertical-demo" router background-color="#141414" text-color="#AAAAAA" active-text-color="#FFF">
					<template v-for="(item, index) in navList">
						<div :key="index">
							<el-menu-item :index="item.path" v-if="!item.children">
								<img class="nav_icon" :src="require(`../assets/images/nav${index + 1}${pathId == item.path?'_on':''}.png`)" alt="">
								<span slot="title">{{item.title}}</span>
							</el-menu-item>

							<el-submenu :index="item.path" v-if="item.children">
								<template slot="title">
									<img class="nav_icon" :src="require(`../assets/images/nav${index + 1}${pathId == item.path?'_on':''}.png`)" alt="">
									<span slot="title" :style="pathId == item.path?'color: #fff':''">{{item.title}}</span>
									<div class="unfold_icon" :class="pathId == item.path?'up':''"></div>
								</template>
								
								<el-menu-item-group>
									<el-menu-item 
									v-for="(item2,index2) in item.children" :key="index2" 
									:index="`${item.path}${item2.path}`">
										{{item2.title}}
									</el-menu-item>
								</el-menu-item-group>

							</el-submenu>
							
						</div>

					</template>
				</el-menu>
			</div>
			<!-- 右边主体 -->
			<div class="main_right flex1">
				<router-view></router-view>
			</div>	 
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				navList:[
					{
						path: '/client',
						title: '客户信息',
					},
					{
						path: '/supplier',
						title: '供应商信息',
					},
					{
						path: '/material',
						title: '物料编码',
						children: [
							{
								path: '/upstream',
								title: '上游物料',
								
							},
							{
								path: '/downstream',
								title: '下游物料',
								
							}
						]
					},
					{
						path: '/recycler',
						title: '回收商信息',
					}
				],
				pathId: '',
				userInfo: ''
			}
		},
		watch:{
			$route(to, from) {
				// console.log("到哪个页面去", to.path);
				this.routeProcess(to.path,'init')
			}
		},
		created() {
			this.pathId = this.routeProcess(this.$route.path)
			this.userInfo = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')) : ''
			if(this.$route.path == '/'){
				this.$router.push('/client')
			}
		},
		mounted() {},
		methods: {
            checkSize(file){//上传之前
                const isMaxSize = file.size / 1024 / 1024 < 20;
                if(!isMaxSize){
                    this.$message.error('上传图片大小不能超过 20MB!');
                }
                return isMaxSize
            },
            handleSuccess(res,key){
                // console.log(res)
				this.userInfo.avatar = res.msg
				let parm = {
					avatar:this.userInfo.avatar,
					userId: this.userInfo.userId
				}
				this.editAvatar(parm)

            },
			editAvatar(parm){
				this.$api.editAvatar(parm).then(res=>{
					if(res.code == 200){
						// this.userInfo = res.data
						this.$message.success('修改成功')
						localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
					}
				})
			},
			routeProcess(path,init){
				// 获取当前页面路由 根据路由给id赋值
				let pathLength = (path.match(/\//g) || []).length
				let menuId = path
				if(pathLength > 1){
					let index =  path.indexOf('/', path.indexOf('/') + 1)
					menuId = path.substring(0, index)
				}
				if(init){
					this.pathId = menuId
				}else{
					return menuId
				}

			},

		}
	}
</script>

<style scoped lang="scss">
	.header {
		width: 100%;
		height: 60px;
		background: #141414;
		padding: 0 20px;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);

		.logo_img {
			width: 280px;
		}

		.avatar {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			object-fit: cover;
		}

	}
	.user_info{
		padding: 20px;
		margin: -12px;
		.avatar_box{
			overflow: hidden;
			border-radius: 50%;
			.avatar{
				width: 40px;
				height: 40px;
				display: block;
				object-fit: cover;
			}
			.avatar_box_bg{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($color: #000000, $alpha: .3);
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateY(-100%);
				transition: .3s;
				cursor: pointer;
				img{
					width: 16px;
					height: 16px;
					object-fit: contain;
				}
			}
			&:hover{
				.avatar_box_bg{
					transform: translateY(0);
				}
			}

		}
		.user_name{
			font-weight: 500;
			font-size: 14px;
			color: #333333;
			line-height: 20px;
		}
		.phone{
			font-weight: 400;
			font-size: 12px;
			color: #999999;
			line-height: 17px;
			margin-top: 2px;
		}
	}
	::v-deep .nav{
		width: 200px;
		height: calc(100vh - 60px);
		min-height: 800px;
		overflow: auto;
		background: #141414;
		.line-img{
			width: 100%;
			display: block;
			margin-bottom: 10px;
		}
		.el-menu{
			border: none;
		}
		.nav_icon{
			width: 20px;
			height: 20px;
			margin-right: 8px;
		}
	}
	.main_right{
		height: calc(100vh - 60px);
		min-height: 800px;
		min-width: 1200px;
		overflow: auto;
	}
	::v-deep .upload_avatar{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		.el-upload{
			width: 100%;
			height: 100%;
		}
	}
	::v-deep .is-active{
		background: rgba($color: #fff, $alpha: .1) !important;
	}
	::v-deep .el-menu-item-group__title{
		padding-top: 0;
		padding-bottom: 0;
	}
	::v-deep .el-submenu__title i{
		opacity: 0;
	}
	.unfold_icon{
		width: 9px;
		height: 5px;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translate(0, -50%);
		// transition: .3s;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-image: url('../assets/images/down4.png');
	}
	.is-opened .unfold_icon{
		transform: translate(0, -50%) rotate(-180deg);

	}
	.is-opened .unfold_icon.up{
		background-image: url('../assets/images/up.png');
		transform: translate(0, -50%);

	}
	::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
		border: none;
	}
	.el-submenu .el-menu-item{
		padding-left: 48px !important;
	}

</style>